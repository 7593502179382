section.home3-main-section .content-box h1 {
    font-size: 48px !important;
    font-weight: 700 !important;
    text-transform: capitalize;
    background-color: #000000;
    background-image: linear-gradient(45deg, #2E4E9A, #1CA180);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
}
h3.mynewlogo {
    color: #000;
    font-weight: 700;
    margin-top: 6px;
}
.liite {
    margin-bottom: 34px;
}
.home3-cta a {
    padding: 20px 30px;
    border-radius: 10px;
    color: #fff;
    font-size: 20px;
    background: #000;
}
h3.Get {
    font-size: 22px !important;
    text-transform: capitalize;
    font-weight: 600;
}

section.home3-main-section {
    padding-top: 60px;
    padding-bottom: 80px;
}


section.home3-main-section .bootom-bnr {
    margin-top: 70px;
}
img.cal03 {
    width: 29px;
    margin-right: 10px;
}
img.girlcar {
    border-radius: 10px;
    margin-bottom: 40px;
width: 100%;
}
ul.third-row {
    list-style: none;
    margin: 0 auto;
    display: inline-flex;
    margin-top: 60px;
    gap: 30px;
}
section.home3-main-section a.contacted {
    color: #365594;
    font-weight: 500;
}
.arrow02 {
    margin-top: -92px;
    margin-bottom: 10px;
}
.home3-cta1 a {
    background: #205361;
    padding: 20px 30px;
    border-radius: 10px;
    color:#fff;
    font-size: 20px;
}
@media (max-width: 767px) and (min-width: 320px){
    section.home3-main-section .content-box h1 {
        font-size: 26px !important;}
        section.home3-main-section {
            padding-top: 40px;
            padding-bottom: 60px;
        }

        .arrow02 {
            margin-top: -51px;
            margin-bottom: 0;
        }
        ul.third-row {
            list-style: none;
            margin: 0 auto;
            display: block;
            margin-top: 31px;
            gap: 0;      
         padding-left: 0px;
        }
        .home3-cta1 a {
           padding: 18px 19px;
            font-size: 16px;
        }
        .home3-cta {
            margin-top: 30px;
        }
        .home3-cta a {
           padding: 18px 19px;
         font-size: 16px;
        }
}


@media (max-width: 991px) and (min-width: 768px){
    section.home3-main-section .content-box h1 {
        font-size: 35px !important;}
        
        p.home2-colpyy {
            float: right;
            color: #fff;
            font-size: 14px;
            text-align: center;
            border-top:0px !important;
            padding-top: 15px;
        }
    
}
@media (max-width: 1023px) and (min-width: 320px){
    h3.mynewlogo {
        font-size: 25px !important;
   }
}