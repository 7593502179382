@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

section.myheader {
    border-bottom: 1px solid #7777772b;
  }
  section.main-section {
    padding-top: 60px;
    padding-bottom: 60px;
   }
   .form-btn input.form-rol {
    background: #000;
    color: #fff;
    border: #000;
    padding: 12px 50px;
}
section.main-content a {
    color: #2aa88d;
}
textarea:focus, input:focus{
    outline: none;
}
   div#contv textarea#message {
    border: 1px solid #dee2e6;
    font-size: 16px;
    line-height: 30px;
    background-color: #ffffff;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 100%;
    height: 82px !important;
    padding: 10px;
}
div#contv input.form-control {
    border: 1px solid #dee2e6;
    font-size: 16px;
    line-height: 30px;
    background-color: #ffffff;
    border-radius: 5px;
    margin-bottom: 10px;
}
div#contv {
    width: 80%;
    margin: 0 auto;
}
section.main-content ul {
    padding-left: 15px;
}
section.main-content ul li {
    line-height: 30px;
}
section.main-content {
    padding: 60px 0px 50px 0px;
}
.headw h2 {
    color: #fff;
}
section.privacy-policy {
    background: #111010;
    padding: 30px 10px;
    text-align: center;
}
/* .hr-lines:after{
    content:" ";
    height: 2px;
    width: 130px;
    background: #ff8a00;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
  } */
  .hr-lines{
    position: relative;
     background: #fff;
    max-width: 40%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: -20px;
    font-weight: 700;
    text-transform: capitalize;
  }
/* .hr-lines:before{
    content:" ";
    display: block;
    height: 2px;
    width: 130px;
    position: absolute;
    top: 50%;
    left: 0;
    background: #ff8a00;
  } */
.content-box h1 {
    font-size: 48px !important;
    font-weight: 700 !important;
    text-transform: capitalize;
    background-color: #000000;
    background-image: linear-gradient(45deg, #2E4E9A, #1CA180);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
}

.headercnt .box-body p a {
    color: #000;
    font-size: 26px !important;
    font-weight: 800;
    letter-spacing: -.15px;
    line-height: 32px;
    margin-bottom: 0;
    font-family: sans-serif;
}
p.accident {
    font-size: 26px;
    margin-bottom: 43px;
}
.headercnt {
      border-radius: 0;
    display: flex;
    position: relative;
    float: right;
}
.chatmsg {
    margin: 1px 20px 0 0;
    text-align: center;
    width: 54px;
}
h3.nav-info-box-title {
    color: #4e4747;
    font-size: 16px !important;
    font-weight: 400;
    letter-spacing: -.15px;
    line-height: 22px;
    margin: 0 0 5px;
    padding: 0;
    text-transform: none;
}

section.copy-right01 {
    text-align: center;
    background: #000;
    color: #fff;
    padding-top: 10px;
}
img.mainlogo {
    margin-top: 10px;
    width: 60%;
}
section.myheader {
    border-bottom: 1px solid #7777772b;
    padding-top: 15px;
 }
.bootom-bnr {
    margin-top: 60px;
}
a.contacted {
    color: #ff8a00;
    font-weight: 500;
}
.cta2 {
    padding: 1.25rem;
    border-radius: 0.3125rem;
    color: #282929;
    /* box-shadow: 0px 20px 40px rgba(17, 56, 91, 0.1); */
    height: 100%;
    text-align: center;
    border: 1px solid #77777721;
    background: #f7f7f7;
}
.cta1 {
    /* background-image: url(../images/bnr.png);
    background-position: center;
    background-repeat: no-repeat; */
    padding: 1.25rem;
    border-radius: 0.3125rem;
    color: #668997;
    /* box-shadow: 0px 20px 40px rgba(17, 56, 91, 0.1); */
    height: 100%;
    text-align: center;
    border: 1px solid #f3ddb5;
    background: #fffbf4;
}
p.small {
    color: #000;
}
.box-call-body {
    flex-wrap: wrap;
    margin: 0 auto;
    display: inline-flex;
}
h5.Road {
    color: #000;
    font-size: 26px !important;
    font-weight: 600;
}

.settlement {
    /* background-image: url(../images/bnr.png);
    background-position: center;
    background-repeat: no-repeat; */
    background-size: cover;
    border-radius: 13px;
    padding: 80px 50px;
    border: 1px solid;
}
img.housephone {
    width: 100%;
    rotate: 30deg;
    margin-top: 12px;
}
p.colpyy a {
    color: #f68531;
}
.box-call-info h3 {
    margin-left: 15px;
}
.box-call-info h3 a{
    color: #ff8a00;
    font-weight: 700;
}

@media (max-width: 767px) and (min-width: 320px){
    .headw h2 {
        color: #fff;
        font-size: 25px !important;
    }
    section.main-content h3 {
        font-size: 24px !important;
    }
    section.main-content {
        padding: 40px 0px 20px 0px;
    }
    .mylogo {
        text-align: center;
    }
    img.mainlogo {
        margin-top: 10px;
        width: 100%;
        margin-bottom: 15px;
    }
    .headercnt {
        float: none;
        display: block;
        text-align: center;
    }
    .content-box h1 {
        font-size: 30px !important;
        font-weight: 700 !important;
        line-height: 40px;
        margin-bottom: 10px;
    }
    .box-call-info h3 a {
           font-size: 22px;
    }
    .box-call-info h3 {
        margin-left: 6px;
    }
    .cta2 {
        margin-top: 30px;}
    h5.Road {
         font-size: 22px !important;
       }
    .settlement {
        background-size: cover;
        border-radius: 0;
        padding: 0;
        border: navajowhite;
    }
    .hr-lines {
       max-width: 100%;
        margin-bottom: 22px;
        font-size: 20px !important;
     }    
    section.main-section {
        padding-top: 40px;
        padding-bottom: 30px;
    }
    p.accident {
        font-size: 19px;
        margin-bottom: 30px;
    }
    .chatmsg {
        width: 43px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 8px;
        margin-top: 5px;
    }
    .headercnt .box-body p a {
     font-size: 22px !important;}
}

@media (max-width: 1023px) and (min-width: 768px){
    img.mainlogo {
        margin-top: 10px;
        width: 100%;
    }
    .chatmsg {
       width: 45px;
    }
    .content-box h1 {
        font-size: 40px !important;
    }
    .hr-lines {
       max-width: 62%;
       }
       h5.Road {
        font-size: 20px !important;
    }
    section.main-section {
        padding-top: 50px;
        padding-bottom: 30px;
    }
    .bootom-bnr {
        margin-top: 45px;
    }
    .box-call-info h3 a {
      font-size: 24px !important;
     }
       .settlement {
        background-size: cover;
        border-radius: 13px;
        padding: 60px 15px 15px;
        border: 1px solid;
    }
    p.accident {
        font-size: 20px;
        margin-bottom: 34px;
    }
}

@media (max-width: 1300px) and (min-width: 1024px){
    .settlement {
        background-size: cover;
        border-radius: 13px;
        padding: 60px 30px 40px;
        border: 1px solid;
    }
    .content-box h1 {
        font-size: 40px !important;
    }
    .hr-lines {
        max-width: 62%;
    }
    .chatmsg {
      width: 46px;
    }
    .box-call-info h3 a {
        font-size: 24px !important;
    }
    img.mainlogo {
        margin-top: 10px;
        width: 76%;
    }
}
