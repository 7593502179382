section.home2 {
    background: #01132f;
    padding-top: 20px;
    padding-bottom: 100px;
}
.home2-mylogo {
    float: left;
}
.home2-settlement .settlement {
    background-size: cover;
    border-radius: 13px;
    padding: 80px 50px;
    border: 1px solid;
    background: #fff;
}
.home2-settlement .form-btn input.form-rol {
    background: #b2dd31;
    color: #000;
    border: #b2dd31;
    padding: 12px 50px;
}
.home2-content-box h1 {
    color: #b2dd31;
    text-transform: capitalize;
    font-weight: 700;
}
img.boy {
    position: absolute;
    right: 0;
    width: 43%;
    bottom: 0;
}
div#home-header h3.nav-info-box-title {
    color: #fff;}
div#home-header {
    border-bottom: 1px solid #3e4552;
}
div#home2-content {
    padding-top: 80px;
}
div#home-header .headercnt .box-body p a {
    color: #fff;}

.home2-content-box p.accident {
    font-size: 22px;
    margin-bottom: 50px;
    color: #fff;
}
.home2-content-box .hr-lines {
    color: #fff;
    border-radius: 10px;
    position: relative;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 0;
    font-weight: 700;
    text-transform: capitalize;
    background: transparent;
    padding-bottom: 40px;
    border-bottom: 1px solid;
}
.home2-settlement {
    margin-top: 25px;
}
.home2-cta1 {
    background: #ffffff;
    border-radius: 10px;
    padding-left: 150px;
}
.home2-cta1 {
  
    background: #ffffff;
    border-radius: 10px;
}
.home2-mylogo h3 {
    color: #fff;
    margin-top: 10px;
}
img.home2call {
    width: 20px;
    margin-right: 10px;
}
section.home2-copy-right {
    padding-top: 30px;
    background: #1d1d1d;
    padding-bottom: 10px;
}
section.home2-copy-right ul {
    display: flex;
    padding-left: 0px;
}
p.home2-colpyy a {
    color: #5ed6bc;
}
section.home2-copy-right ul li {
    color: #fff;
    padding-right: 24px;
    font-size: 14px;
}
section.home2-copy-right ul li a {
    color: #fff;
}
p.home2-colpyy {
    float: right;
    color: #fff;
    font-size: 14px;
}
a.home2-call {
    background: #b2dd31;
    color: #04132d;
    padding: 12px 20px;
    border-radius: 5px;
    font-weight: 600;
}
section.home2 div#colborder {
    margin-top: 50px;
}
.home2-cta1-content {
    padding-top: 50px;
    padding-bottom: 50px;
}
.home2-btn {
    margin-top: 30px;
}
img.girl {
    position: absolute;
    left: 0;
    width: 45%;
    bottom: 0;
}

.home2-cta2 {
    display: flex;
    background: #ffffff;
    border-radius: 10px;
    padding-left: 30px;
}
.Dedicated i {
    padding: 0px 10px;
    color: #b5e04d;
    font-size: 34px;
    background: #04132d;
    margin-top: -30px;
    position: relative;
    border: 1px solid;
}





.home2-mylogo h3 {
    color: #fff;
    margin: 0 auto;
    text-align: center;
    font-size: 30px !important;
    margin-top: 10px;
}
@media (max-width: 992px) and (min-width: 320px){
    .home2-settlement .settlement {
        background-size: cover;
        border-radius: 13px;
        padding: 30px 0px;
        border: 1px solid;
        background: #fff;
    }
    section.home2 div#colborder {
        margin-top: 18px;
    }
    img.boy {
        position: relative;
        right: unset;
        width: 100%;
        bottom: unset;
    }
    section.home2-copy-right ul {
        display: block;
        padding-left: 0px;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 10px;
    }
    p.home2-colpyy {
        float: right;
        color: #fff;
        font-size: 14px;
        text-align: center;
        border-top: 1px solid;
        padding-top: 15px;
    }
    section.home2-copy-right ul li {
        color: #fff;
        padding-right: 24px;
        font-size: 14px;
        line-height: 28px;
        list-style: none;
    }
    section.home2 {
        padding-bottom: 60px;
    }
    .home2-cta2 {
        display: block;
        background: #ffffff;
        border-radius: 10px;
        padding-left: 0;
    }
    img.girl {
        position: relative;
        left:unset;
        top: unset;
        width: 100%;
    }
    .home2-cta1 {
        background: #ffffff;
        border-radius: 10px;
        padding-left: 0;
    }
    .home2-mylogo h3 {
        color: #fff;
        margin-top: 10px;
        margin: 0 auto;
        text-align: center;
        font-size: 24px !important;
        margin-bottom: 12px;
    }
    div#home2-content {
        padding-top: 30px;
    }
    .home2-content-box h1 {
        font-size: 27px !important;
    }
    p.accident {
        font-size: 19px !important;
        margin-bottom: 30px !important;
    }
}

@media (max-width: 1023px) and (min-width: 992px){
    div#home2-content {
        padding-top: 40px;
    }
}
@media (max-width: 1199px) and (min-width: 1024px){
    div#home2-content {
        padding-top: 40px;
    }
    section.home2 {
        background: #01132f;
        padding-top: 20px;
        padding-bottom: 60px;
    }
}
@media (max-width: 1300px) and (min-width: 1200px){}
@media (max-width: 1399px) and (min-width: 1301px){}
@media (max-width: 1440px) and (min-width: 1400px){}
@media (max-width: 1600px) and (min-width: 1441px){}
@media (max-width: 1920px) and (min-width: 1600px){}